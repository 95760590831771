.hero {
  &_bg {
    position: relative;
  }

  &_intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
