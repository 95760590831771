.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--light);
  z-index: 3;

  &_deco,
  &_text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &_deco {
    .deco_section {
      flex: 1;
      background-color: black;
    }
  }

  &_text {
    > * {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10vw;
      color: var(--dark);
      text-align: center;
    }
  }
}
