footer {
  width: 100vw;
  overflow-x: hidden;
  min-height: 30vh;
  padding: 5rem 1rem 1rem;
  background-color: #ffe699;
  font-size: 4.2vw;
  text-align: center;

  .footer_disclaimer {
    width: 100%;
    display: flex;
    justify-content: center;

    a {
      color: #0000EE;
      text-decoration: underline;
    }
  }

  .footer_contact {
    margin-top: 12rem;
    font-weight: bold;

    p {
      font-size: 1.5rem;
    }
  }

  .footer_deco {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-30deg);
    position: absolute;
    top: 90%;
    right: 10%;
    font-size: 1.3rem;
    cursor: pointer;
    pointer-events: all;

    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }

    @media screen and (min-width: 812px) {
      width: 200px;
      height: 200px;
      top: 80%;
      right: 15%;
    }
  }

  @media screen and (min-width: 812px) {
    min-height: 100vh;
  }
}
