// Modern CSS reset: https://github.com/hankchizljaw/modern-css-reset

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol */
ul,
ol {
  list-style: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

[type="button"] {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}



::-webkit-scrollbar-track {
  background-color: #d3c7c7;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 5px;
  height: 60px;
  width: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(92, 91, 91);
}

:focus {
  //outline: 1px solid var(--dark);
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
