//Global Styles
@import url("https://fonts.googleapis.com/css2?family=New+Tegomin&display=swap");
html {
  width: 100vw;
}

[data-scroll] {
  overflow-y: hidden;
}

body {
  overflow-x: hidden;
  font-family: "New Tegomin", serif;
  line-height: 1.5;
  width: 100vw;
}

.wrapper {
  overflow-x: hidden;
}

h1,
h2,
h3 {
  line-height: 1.1;
  font-weight: lighter;
}

h2 {
  font-size: 9vw;

  @media screen and (min-width: 812px) {
    font-size: 11.5rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 8.5vw;
  }
}

h3 {
  font-size: 14.6vw;
  line-height: 0.8;
}

h4 {
  font-size: 15vw;
  letter-spacing: -8px;
  font-weight: lighter;

  @media screen and (min-width: 812px) {
    font-size: 7rem;
  }

  @media screen and (min-width: 992px) {
    font-size: 8vw;
  }
}

a {
  color: currentColor;
  text-decoration: none;
}

p {
  font-size: 0.9rem;
}

.vh {
  //Visually hidden
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

/*Locomotive scroll*/
html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}
[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

footer {
}

.padding {
  padding: 2rem;

  @media screen and (min-width: 812px) {
    padding: 3rem;
  }
}
