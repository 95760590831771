.project {
  position: relative;
  padding-top: calc(10vh);

  &_bg__round {
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 50%;
    width: 100vw;
    height: 100vw;
    z-index: -1;
  }

  &_bg__square {
    top: 50vw;
    position: absolute;
    width: 100vw;
    height: 60vh;
    z-index: -1;
  }

  &_link {
    display: flex;
    justify-content: center;
    width: min-content;
    margin-left: auto;
    margin-right: auto;
  }

  &_media__wrapper {
    position: relative;
    padding-bottom: 130%;
  }

  &_card {
    pointer-events: all;
    position: relative;
    width: 45vw;
    border-radius: 24px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;

    :hover {
      transform: scale(1.2);
    }

    @media screen and (min-width: 812px) {
      width: 21vw;
    }

    &:nth-child(2) {
      background-color: rgb(148, 95, 201);
      transform-origin: left top;
      top: -7vw;
      left: 10%;
      z-index: 1;
      background-position: left top;
    }

    &:last-child {
      background-color: #bb5b0b;
      transform-origin: left top;
      right: 10%;
      background-position: right;
    }
  }

  &_text {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 2rem;

    h4 {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: 991px) {
      padding-top: 0rem;
      padding-bottom: 8rem;
    }

    h3 {
      margin-bottom: 2.5rem;
    }
  }
}
