.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  padding: 2rem;

  @media screen and (min-width: 812px) {
    padding: 3rem;
  }

  &_nav {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
  }

  &_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--light);
    padding: 8rem;
    z-index: 1;
    opacity: 0;
    font-size: 7.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    overflow: hidden;

    .social_links {
      margin-top: 3rem;
      display: flex;
      justify-content: space-between;
    }

    @media screen and (min-width: 812px) {
      font-size: 4.7vw;
    }
  }

  &_hamburger {
    position: relative;
    height: 1rem;
    width: 2rem;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: var(--dark);
      transition: all 0.3s ease-in-out;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      left: 0;
    }

    &:hover::before,
    &:hover::after {
      transform: rotate(180deg);
    }
  }

  &_hamburger[aria-expanded="true"] {
    &::before {
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    }

    &::after {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }

  &_menu__links {
    display: block;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    opacity: 1;

    &.social {
      display: inline-block;
      font-size: 2rem;
    }
  }
}
